import React, {useContext, useEffect, useState} from 'react';
import axios from 'axios';
import Recipe from "../Recipe/Recipe";
import './Browse.css';
import {Col, Row, Form, Button} from "react-bootstrap";
import { AuthContext } from "../../AuthContext";
import axiosInstance from "../../config/axiosConfig";


const Browse = () => {
  const [items, setItems] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterByCreator, setFilterByCreator] = useState(false);
  const { profile } = useContext(AuthContext);

  useEffect(() => {
    console.log('Browse component mounted');
      axiosInstance.get('recipes')
        .then(response => {
          console.log('Fetched items:', response.data);
          setItems(response.data);
        })
        .catch(error => {
          console.error('Error fetching items:', error);
        });
  }, []);

    // const filteredItems = items.filter(item => {
    const filteredItems = Array.isArray(items) ? items.filter(item => {
        const matchesSearchTerm = item.name.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesCreator = !filterByCreator || item.creator === profile.email;
        return matchesSearchTerm && matchesCreator;
    }) : [];

  return (
      <>
          <div className="d-flex mb-3 mt-3">
              <Form.Control
                  type="text"
                  placeholder="Search recipes..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="me-2"
              />
              <Button
                  variant="primary"
                  onClick={() => setFilterByCreator(!filterByCreator)}
              >
                  {filterByCreator ? 'Show All' : 'Show My Recipes'}
              </Button>
          </div>
          <Row>
              {filteredItems.map((recipe) => (
                  <Col sm={12} md={6} lg={4} xl={3}>
                      <Recipe
                          key={recipe.id}
                          id={recipe.id}
                          title={recipe.name}
                          description={recipe.description}
                          image={recipe.imageUrl}
                          ingredients={recipe.ingredients}
                          minutesToCook={recipe.minutesToCook}
                          diet={recipe.diet}
                          servings={recipe.servings}
                    creator={recipe.creator}
                />
              </Col>
          ))}
        </Row>
      </>
  );
}

export default Browse;