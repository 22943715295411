import React, { useContext } from 'react';
import { Link, Outlet } from 'react-router-dom';
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import { AuthContext } from '../../AuthContext';
import Image from 'react-bootstrap/Image';
import './MyNavbar.css';

const MyNavbar = () => {
    const { profile, handleLogout } = useContext(AuthContext);

    console.log('MyNavbar profile:', profile);

    return (
        <>
            <Navbar bg="dark" variant="dark">
                <Container>
                    <Navbar.Brand as={Link} to="/">Recipe Planner</Navbar.Brand>
                    <Nav className="me-auto">
                        <Nav.Link as={Link} to="/">Browse</Nav.Link>
                        <Nav.Link as={Link} to="/planer">Planer</Nav.Link>
                        <Nav.Link as={Link} to="/new-menues">Add Recipes</Nav.Link>
                    </Nav>
                    <Nav className="ms-auto">
                        {profile && (
                            <>
                                <Nav.Item>
                                    <Image src={profile.picture} roundedCircle width="30" height="30" alt="user image" />
                                </Nav.Item>
                                <Nav.Item className="ms-2">
                                    <Navbar.Text>{profile.name}</Navbar.Text>
                                </Nav.Item>
                                <Nav.Item className="ms-2">
                                    <Button variant="outline-light" className="btn-xs" onClick={handleLogout}>Logout</Button>
                                </Nav.Item>
                            </>
                        )}
                    </Nav>
                </Container>
            </Navbar>
            <Outlet />
        </>
    );
}

export default MyNavbar;