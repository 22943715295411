import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Login from './components/Login/Login';
import { AuthProvider, AuthContext } from './AuthContext';

// Importing the Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';

const RootComponent = () => {

  console.log(`Running in ${process.env.NODE_ENV} mode`);
  console.log(`Base URL: ${process.env.REACT_APP_BASE_URL}`);

  return (
    <GoogleOAuthProvider clientId='917943873464-jd273depmfsjumueic758ri3iilhcnm2.apps.googleusercontent.com'>
      <React.StrictMode>
        <BrowserRouter>
          <AuthProvider>
            <AuthContext.Consumer>
              {({ isLoggedIn }) => (isLoggedIn ? <App /> : <Login />)}
            </AuthContext.Consumer>
          </AuthProvider>
        </BrowserRouter>
      </React.StrictMode>
    </GoogleOAuthProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<RootComponent />);

reportWebVitals();