// axiosConfig.js
import axios from 'axios';

const axiosInstance = axios.create({
    // baseURL: 'http://188.245.190.99:8080/api/', // Set your base URL here
    // baseURL: 'http://172.17.0.1:8080/api/',
    // baseURL: 'http://localhost:8080/api/',
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

export default axiosInstance;