import './RecipeAsAList.css'
import { Card, Row, Col, Image } from "react-bootstrap";
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDrag } from 'react-dnd'


const RecipeAsAList = ({ id, title, description, image, ingredients }) => {
    useEffect(() => {
        console.log('RecipeAsAList rendered:', { id, title, description, image, ingredients });
      }, [id, title, description, image, ingredients]);

      
    const [{ isDragging }, drag] = useDrag(() => ({
      type: 'RECIPE',
      item: { id, title, description, image, ingredients },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }));

    console.log('RecipeAsAList image:', image); // Add this for debugging

 return (
    <Card ref={drag} style={{ opacity: isDragging ? 0.5 : 1, marginBottom: '10px' }}>
      <Card.Body>
        <div className="d-flex">
          {image ? (
            <Image 
              src={image} 
              alt={title} 
              style={{ width: '50px', height: '50px', objectFit: 'cover', marginRight: '10px' }} 
              onError={(e) => {
                console.error('Image failed to load:', image);
                e.target.src = 'path/to/fallback/image.jpg'; // Replace with a path to a default image
              }}
            />
          ) : (
            <div style={{ width: '50px', height: '50px', backgroundColor: '#ccc', marginRight: '10px' }}></div>
          )}
          <div>
            <Card.Title>{title}</Card.Title>
            <div style={{ color: 'black', backgroundColor: 'white' }}>
              {description || 'No description available'}
            </div>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default RecipeAsAList;