import './Recipe.css'
import {Button, Card, Badge } from "react-bootstrap";
import React, {useContext} from "react";
import { useNavigate } from 'react-router-dom';
import { AuthContext } from "../../AuthContext";

function Recipe({ id, title, description, image, ingredients , minutesToCook, diet , key, servings, creator}) {

    const navigate = useNavigate();
    const { profile } = useContext(AuthContext);

    const handleEdit = () => {
        console.log("Navigating with recipe data:", { id, title, description, image, ingredients, creator });
        navigate('/edit-recipe', { 
            state: { 
                recipe: {
                    id: id,
                    name: title,
                    description,
                    imageUrl: image,
                    ingredients,
                    minutesToCook: minutesToCook || '',
                    diet: diet || '',
                    servings: servings || '',
                    creator: creator || ''
                } 
            } 
        });
    };

    return (
        <Card style={{ width: '18rem', position: 'relative' }}>
            {creator === profile.email && (
                <Badge bg="primary" style={{ position: 'absolute', top: '10px', right: '10px' }}>
                    My Recipe
                </Badge>
            )}
            <Card.Img variant="top" src={image} />
            <Card.Body>
                <Card.Title>{title}</Card.Title>
                {description}
                <Button variant="primary" onClick={handleEdit}>See Details</Button>
            </Card.Body>
        </Card>
    );
}

export default Recipe;