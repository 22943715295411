import React, { useContext } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import MyNavbar from "./components/MyNavbar/MyNavbar";
import Browse from "./components/Browse/Browse";
import Planer from "./components/Planer/Planer";
import AddRecipe from "./components/AddRecipe/AddRecipe";
import EditRecipe from "./components/EditRecipe/EditRecipe";
import Login from "./components/Login/Login";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { AuthContext } from './AuthContext';

function App() {
  const { isLoggedIn } = useContext(AuthContext);

  console.log('isLoggedIn:', isLoggedIn);

  return (
      <DndProvider backend={HTML5Backend}>
        <Routes>
          <Route path="/login" element={<Login />} />
          {isLoggedIn ? (
              <>
                <Route path="/" element={<MyNavbar />}>
                  <Route index element={<Browse />} />
                  <Route path="browse" element={<Browse />} />
                  <Route path="planer" element={<Planer />} />
                  <Route path="planer/:id" element={<Planer />} />
                  <Route path="new-menues" element={<AddRecipe />} />
                  <Route path="edit-recipe" element={<EditRecipe />} />
                </Route>
              </>
          ) : (
              <Route path="*" element={<Navigate to="/login" />} />
          )}
        </Routes>
      </DndProvider>
  );
}

export default App;