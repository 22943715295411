import React from 'react';

function ShoppingList({ ingredients }) {
    return (
        <div>
            <h2>Shopping List</h2>
            <ul>
                {ingredients.map((ingredient, index) => (
                    <li key={index}>
                        {ingredient.name} - {ingredient.amount} {ingredient.unit} ({ingredient.comment})
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default ShoppingList;