import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './AddIngredient.css';
import {Button, Col, Form, Row, ListGroup} from "react-bootstrap";
import Papa from 'papaparse';
import lebensmittel from '../../assets/lebensmittel.csv';

const AddIngredient = ({ingredient, updateIngredient, removeIngredient}) => {
    console.log("AddIngredient props:", {ingredient, updateIngredient, removeIngredient});

    const [suggestions, setSuggestions] = useState([]);
    const [allIngredients, setAllIngredients] = useState([]);

    useEffect(() => {
        // Parse the CSV file
        Papa.parse(lebensmittel, {
            download: true,
            complete: (result) => {
                setAllIngredients(result.data.flat());
            }
        });
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        const updatedIngredient = { ...ingredient, [name]: value };
        updateIngredient(updatedIngredient);

        if (name === 'name' && value.length >= 3) {
            const filteredSuggestions = allIngredients.filter(ing =>
                ing.toLowerCase().includes(value.toLowerCase())
            ).slice(0, 5);
            setSuggestions(filteredSuggestions);
        } else {
            setSuggestions([]);
        }
    };

    const handleSuggestionClick = (suggestion) => {
        const updatedIngredient = { ...ingredient, name: suggestion };
        updateIngredient(updatedIngredient);
        setSuggestions([]);
    };

    return (
        <Row className="mb-3">
            <Col>
                <Form.Control
                    name="name"
                    value={ingredient.name || ''}
                    onChange={handleChange}
                    placeholder="Ingredient"
                />
                {suggestions.length > 0 && (
                    <ListGroup>
                        {suggestions.map((suggestion, index) => (
                            <ListGroup.Item key={index} onClick={() => handleSuggestionClick(suggestion)}>
                                {suggestion}
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                )}
            </Col>
            <Col>
                <Form.Control
                    name="amount"
                    value={ingredient.amount || ''}
                    onChange={handleChange}
                    placeholder="Amount"
                />
            </Col>
            <Col>
                <Form.Control
                    as="select"
                    name="unit"
                    value={ingredient.unit}
                    onChange={handleChange}
                >
                    <option value="PIECE">PIECE</option>
                    <option value="GRAMM">GRAMM</option>
                    <option value="KILOGRAMM">KILOGRAMM</option>
                    <option value="LITRE">LITRE</option>
                    <option value="DECILITRE">DECILITRE</option>
                </Form.Control>
            </Col>
            <Col>
                <Form.Control
                    name="comment"
                    value={ingredient.comment || ''}
                    onChange={handleChange}
                    placeholder="Comment"
                />
            </Col>
            <Col>
                <Button variant="danger" onClick={() => removeIngredient(ingredient.listId)}>
                    Remove
                </Button>
            </Col>
        </Row>
    );
};

AddIngredient.propTypes = {
    ingredient: PropTypes.object.isRequired,
    updateIngredient: PropTypes.func.isRequired,
    removeIngredient: PropTypes.func.isRequired,
};

export default AddIngredient;