import React, {useState, useEffect, useContext} from 'react';
import './Planer.css';
import axios from "axios";
import {Container, Row, Col, Form, Button, Image, Modal} from "react-bootstrap";
import RecipeAsAList from '../Recipe/RecipeAsAList/RecipeAsAList';
import {useDrop, useDrag} from 'react-dnd'
import {useNavigate, useParams} from 'react-router-dom';
import {AuthContext} from "../../AuthContext";
import ShoppingList from "./ShoppingList/ShoppingList";
import axiosInstance from "../../config/axiosConfig";


// const baseURL = "http://188.245.190.99:8080/api/";

const TableCell = ({day, time, schedule, setSchedule}) => {
    console.log('Schedule:', schedule); // Log the entire schedule
    const recipe = schedule[day.toLowerCase()] && schedule[day.toLowerCase()][time];
    console.log(`Rendering TableCell for ${day} at ${time}:`, recipe); // Log the recipe

    const [{isDragging}, drag] = useDrag(() => ({
        type: 'TABLECELL',
        item: {recipe, day, time},
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
    }), [recipe, day, time]);

    const handleDrop = (item, day, time) => {
        if (item.day && item.time) {
            // Item is from another cell
            setSchedule(prev => {
                const newSchedule = {...prev};
                // Remove from old position
                delete newSchedule[item.day.toLowerCase()][item.time];
                // Add to new position
                if (!newSchedule[day.toLowerCase()]) newSchedule[day.toLowerCase()] = {};
                newSchedule[day.toLowerCase()][time] = item.recipe;
                return newSchedule;
            });
        } else {
            // Item is from recipe list
            setSchedule(prev => ({
                ...prev,
                [day.toLowerCase()]: {
                    ...(prev[day.toLowerCase()] || {}),
                    [time]: item
                }
            }));
        }
    };

    const [{isOver}, drop] = useDrop(() => ({
        accept: ['RECIPE', 'TABLECELL'],
        drop: (item) => handleDrop(item, day, time),
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    }), [day, time, setSchedule]);

    return (
        <td
            ref={(node) => drag(drop(node))}
            className={`${isOver ? 'bg-light' : ''} ${isDragging ? 'opacity-50' : ''} p-2`}
            style={{height: '100px', width: '150px'}}
        >
            {recipe ? (
                <div className="d-flex flex-column align-items-center">
                    {recipe.image ? (
                        <Image
                            src={recipe.image}
                            alt={recipe.title}
                            thumbnail
                            style={{width: '50px', height: '50px', objectFit: 'cover'}}
                            onError={(e) => {
                                console.error('Image failed to load:', recipe.image);
                                e.target.src = 'path/to/fallback/image.jpg'; // Replace with a path to a default image
                            }}
                        />
                    ) : (
                        <div style={{width: '50px', height: '50px', backgroundColor: '#ccc'}}></div> // Placeholder if no image
                    )}
                    <small className="mt-1">{recipe.title}</small>
                </div>
            ) : null}
        </td>
    );
};

const DeleteArea = ({setSchedule}) => {
    const [{isOver}, drop] = useDrop(() => ({
        accept: ['TABLECELL'],
        drop: (item) => {
            setSchedule(prev => {
                const newSchedule = {...prev};
                delete newSchedule[item.day.toLowerCase()][item.time];
                return newSchedule;
            });
        },
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
        }),
    }), [setSchedule]);

    return (
        <div
            ref={drop}
            className={`delete-area ${isOver ? 'bg-danger' : 'bg-light'} p-3 mt-3`}
            style={{height: '100px', textAlign: 'center', lineHeight: '100px'}}
        >
            Drag here to delete
        </div>
    );
};


const Planer = () => {
    const {id} = useParams();
    const [recipes, setRecipes] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filterByCreator, setFilterByCreator] = useState(false);
    const [schedule, setSchedule] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [planName, setPlanName] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [plans, setPlans] = useState([]);
    const [showShoppingList, setShowShoppingList] = useState(false);
    const [shoppingList, setShoppingList] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState('');
    const navigate = useNavigate();
    const {profile} = useContext(AuthContext);


    const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    const timeSlots = ['08:00', '10:00', '12:00', '14:00', '16:00', '18:00'];

    useEffect(() => {
        if (id) {
            setSelectedPlan(id);
            handleLoadPlan(id);
        }
    }, [id]);

    useEffect(() => {
        if (isModalOpen) {
            fetchPlans();
        }
    }, [isModalOpen]);

    useEffect(() => {
        fetchRecipes();
    }, []);

    useEffect(() => {
        updatePlan();
    }, [schedule]);

    const gatherIngredients = () => {
        const ingredients = [];
        Object.values(schedule).forEach(day => {
            Object.values(day).forEach(recipe => {
                ingredients.push(...recipe.ingredients);
            });
        });
        setShoppingList(ingredients);
    };

    const handleCreateShoppingList = () => {
        gatherIngredients();
        setShowShoppingList(true);
    };

    const fetchRecipes = async () => {
        try {
            const response = await axiosInstance.get('recipes');
            setRecipes(response.data);
        } catch (error) {
            console.error('Error fetching recipes:', error);
        }
    };

    const fetchPlans = async () => {
        try {
            const response = await axiosInstance.get('plans');
            const filteredPlans = response.data.filter(plan => plan.creator === profile.email);
            setPlans(filteredPlans);
        } catch (error) {
            console.error('Error fetching plans:', error);
        }
    };

    const handleLoadPlan = async (selectedPlan) => {
        if (!selectedPlan) return;
        try {
            const response = await axiosInstance.get(`plans/plan/${selectedPlan}`);
            const loadedPlan = response.data;
            console.log('Loaded Plan:', loadedPlan); // Log the loaded plan

            const formattedPlan = {
                id: loadedPlan.id,
                name: loadedPlan.name,
                startDate: loadedPlan.startDate,
                endDate: loadedPlan.endDate,
                recipes: []
            };

            const recipePromises = loadedPlan.entries.map(async (entry) => {
                const recipeResponse = await axiosInstance.get(`recipes/${entry.recipeId}`);
                const recipe = recipeResponse.data;
                return {
                    key: recipe.id,
                    id: recipe.id,
                    title: recipe.name,
                    description: recipe.description,
                    image: recipe.imageUrl,
                    ingredients: recipe.ingredients,
                    selectedDay: entry.selectedDay,
                    timeSlot: entry.timeSlot
                };
            });

            formattedPlan.recipes = await Promise.all(recipePromises);
            console.log('Formatted Plan Recipes:', formattedPlan.recipes); // Log the formatted plan recipes

            const schedule = {};
            formattedPlan.recipes.forEach(entry => {
                const day = entry.selectedDay.toLowerCase();
                const time = entry.timeSlot.replace('SLOT_', '').replace('_', ':');
                if (!schedule[day]) schedule[day] = {};
                schedule[day][time] = entry;
            });

            console.log('Formatted Schedule:', schedule); // Log the formatted schedule

            setPlans(prevPlans => {
                const filteredPlans = prevPlans.filter(plan => plan.id !== formattedPlan.id);
                return [...filteredPlans, formattedPlan];
            });
            setSelectedPlan('');
            setPlanName(formattedPlan.name);
            setStartDate(formattedPlan.startDate);
            setEndDate(formattedPlan.endDate);
            setSchedule(schedule); // Ensure the schedule is set correctly
            setIsModalOpen(false); // Close the modal
            navigate(`/planer/${formattedPlan.id}`); // Correctly update the URL
        } catch (error) {
            console.error('Error loading plan:', error);
        }
    };

    const updatePlan = async () => {
        if (!id) {
            console.error('Plan ID is missing');
            return;
        }

        const entries = Object.entries(schedule).flatMap(([day, slots]) =>
            Object.entries(slots).map(([time, recipe]) => ({
                selectedDay: day.toUpperCase(),
                timeSlot: `SLOT_${time.replace(':', '_')}`,
                recipeId: recipe.id
            }))
        );

        const planData = {
            id, // Ensure the plan ID is included
            name: planName,
            startDate,
            endDate,
            entries
        };

        try {
            const response = await axiosInstance.put('plans', planData);
            console.log('Plan updated successfully:', response.data);
            // You might want to show a success message to the user here
        } catch (error) {
            console.error('Error updating plan:', error);
            // You might want to show an error message to the user here
        }
    };

    useEffect(() => {
        // axios.get(baseURL).then((response) => {
        axiosInstance.get("recipes").then((response) => {
            setRecipes(response.data);
            console.log('Recipes loaded:', response.data);
        }).catch(error => {
            console.error('Error fetching recipes:', error);
        });
    }, []);

    const filteredRecipes = recipes.filter(recipe => {
        const matchesSearchTerm = recipe.name.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesCreator = !filterByCreator || recipe.creator === profile.email;
        return matchesSearchTerm && matchesCreator;
    });

    const handleCreatePlan = async () => {
        if (planName && startDate) {

            const currentDate = new Date().toISOString().split('T')[0]; // Get the current date in YYYY-MM-DD format
            const newPlanName = `${currentDate}-${planName}`; // Combine current date and plan name

            // Check for duplicate plan name
            const duplicatePlan = plans.find(plan => plan.name === newPlanName);
            if (duplicatePlan) {
                alert('A plan with the same name already exists for today.');
                return;
            }

            // Calculate endDate as startDate + 7 days
            const start = new Date(startDate);
            const end = new Date(start);
            end.setDate(start.getDate() + 7);
            const endDate = end.toISOString().split('T')[0];

            const newPlan = {
                name: newPlanName,
                startDate,
                endDate,
                recipes: schedule,
                creator: profile.email
            };
            try {
                const response = await axiosInstance.post('plans', newPlan);
                const createdPlan = response.data;
                // await handleLoadPlan(createdPlan.id); // Load the newly created plan
                setIsModalOpen(false); // Close the modal after loading the plan
                navigate(`/planer/${createdPlan.id}`); // Correctly update the URL
            } catch (error) {
                console.error('Error creating plan:', error);
            }
        } else {
            alert('Please fill in all fields');
        }
    };

    return (
        <>
            <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)}>
                <Modal.Header>
                    <Modal.Title>Create New Meal Plan</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <select
                        value={selectedPlan}
                        onChange={(e) => setSelectedPlan(e.target.value)}
                        style={{width: '100%', maxWidth: '300px', padding: '8px'}}
                    >
                        <option value="">Select a plan</option>
                        {plans.map(plan => (
                            <option key={plan.id} value={plan.id}>{plan.name}</option>
                        ))}
                    </select>
                    <Form>
                        <Form.Group className="mb-3">
                            <Form.Label>Plan Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter plan name"
                                value={planName}
                                onChange={(e) => setPlanName(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Start Date</Form.Label>
                            <Form.Control
                                type="date"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                            />
                        </Form.Group>
                        {/*<Form.Group className="mb-3">*/}
                        {/*  <Form.Label>End Date</Form.Label>*/}
                        {/*  <Form.Control*/}
                        {/*    type="date"*/}
                        {/*    value={endDate}*/}
                        {/*    onChange={(e) => setEndDate(e.target.value)}*/}
                        {/*  />*/}
                        {/*</Form.Group>*/}
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button onClick={() => handleLoadPlan(selectedPlan)}>Load Plan</Button>
                    <Button variant="primary" onClick={handleCreatePlan}>
                        Create new plan
                    </Button>
                </Modal.Footer>
            </Modal>

            {!isModalOpen && (
                <Container fluid>
                    <h1>{planName}</h1>
                    <Row>
                        <Col md={10}>
                            <div className="content">
                                <div className="container">
                                    <div className="table-responsive">
                                        <table className="table table-bordered text-center">
                                            <thead>
                                            <tr className="bg-light-gray">
                                                <th className="text-uppercase">Time</th>
                                                {days.map(day => (
                                                    <th key={day} className="text-uppercase">{day}</th>
                                                ))}
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {timeSlots.map(time => (
                                                <tr key={time}>
                                                    <td className="align-middle">{time}</td>
                                                    {days.map(day => (
                                                        <TableCell
                                                            key={`${day}-${time}`}
                                                            day={day}
                                                            time={time}
                                                            schedule={schedule}
                                                            setSchedule={setSchedule}
                                                        />
                                                    ))}
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <DeleteArea setSchedule={setSchedule}/>
                            <Button variant="primary" onClick={handleCreateShoppingList} className="mt-3">
                                Create Shopping List
                            </Button>
                        </Col>
                        <Col md={2}>
                            <Form.Group className="mb-3">
                                <Form.Control
                                    type="text"
                                    placeholder="Search recipes..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                              <Form.Check
                                  type="checkbox"
                                  className="custom-checkbox-label"
                                  label="Show My Recipes"
                                  checked={filterByCreator}
                                  onChange={() => setFilterByCreator(!filterByCreator)}
                              />
                            </Form.Group>
                            <div className="recipe-list">
                                {filteredRecipes.map((recipe) => {
                                    console.log('Rendering RecipeAsAList:', recipe);
                                    return (
                                        <RecipeAsAList
                                            key={recipe.id}
                                            id={recipe.id}
                                            title={recipe.name}
                                            description={recipe.description}
                                            image={recipe.imageUrl}
                                            ingredients={recipe.ingredients}
                                        />
                                    );
                                })}
                            </div>
                        </Col>
                    </Row>
                    {showShoppingList && <ShoppingList ingredients={shoppingList} />}
                </Container>
            )}
        </>
    );

}
export default Planer;