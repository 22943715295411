import React, {useState, useEffect, useContext} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './EditRecipe.css';
import { useForm } from "react-hook-form";
import {Form, Button, Col, Row} from 'react-bootstrap';

import axios from "axios";
import AddIngredient from "../AddIngredient/AddIngredient";
import {AuthContext} from "../../AuthContext";
import axiosInstance from "../../config/axiosConfig";

function EditRecipe() {
    const location = useLocation();
    const navigate = useNavigate();
    const editingRecipe = location.state?.recipe;
    console.log("Location state:", location.state);


    const [ ingredients, setIngredients ] = useState(editingRecipe?.ingredients || [])
    const [formData, setFormData] = useState({
        "name": '',
        "description": '',
        "imageUrl": '',
        "ingredients": [],
        "id": '',
        "minutesToCook": '',
        "diet": '',
        "servings": '',
        "creator": ''
    });

    const { profile } = useContext(AuthContext);
    const isCreator = profile.email === formData.creator;

    useEffect(() => {
        if (editingRecipe) {
            setFormData({
                id: editingRecipe.id || '',
                name: editingRecipe.name || '',
                description: editingRecipe.description || '',
                imageUrl: editingRecipe.imageUrl || '',
                ingredients: editingRecipe.ingredients || [],
                minutesToCook: editingRecipe.minutesToCook || '',
                diet: editingRecipe.diet || '',
                servings: editingRecipe.servings || '',
                creator: editingRecipe.creator || ''
            });
        }
    }, [editingRecipe]);

    const [listId, setListId] = useState(1)

    const {
        register,
        handleSubmit,
        watch,
        formState: {errors},
    } = useForm()

    
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };
    
    const onSubmit = (data) => {

        // Combine form data with ingredients
        const recipeData = {
            id: formData.id,
            name: formData.name,
            description: formData.description,
            imageUrl: formData.imageUrl,
            ingredients: formData.ingredients,
            minutesToCook: formData.minutesToCook,
            diet: formData.diet,
            servings: formData.servings
        };
        
        // Here you would typically send the data to your backend
        console.log("Submitting formData:", formData);
        console.log("Submitting recipe:", recipeData);
        
        // TODO: Add API call to submit the recipe
        axiosInstance.put('recipes', recipeData)
            .then(response => {
                console.log('Recipe submitted successfully', response.data);
                // Reset form or redirect user
                // Navigate to Browse page after successful submission
                navigate('/browse');
            })
            .catch(error => {
                console.error('Error submitting recipe', error);
            });
    };

    const handleAddToMyRecipes = () => {
        const ingredientsWithoutId = formData.ingredients.map(({ id, ...rest }) => rest);

        const recipeData = {
            name: formData.name,
            description: formData.description,
            imageUrl: formData.imageUrl,
            ingredients: ingredientsWithoutId,
            minutesToCook: formData.minutesToCook,
            diet: formData.diet,
            servings: formData.servings,
            creator: profile.email
        };

        axiosInstance.post('recipes', recipeData)
            .then(response => {
                console.log('Recipe added to my recipes successfully', response.data);
                navigate('/browse');
            })
            .catch(error => {
                console.error('Error adding recipe to my recipes', error);
            });
    };

    const addIngredient = () => {
        setFormData(prevData => ({
            ...prevData,
            ingredients: [
                ...prevData.ingredients,
                {
                    listId: listId,
                    name: '',  // Changed from 'ingredient'
                    unit: 'PIECE',
                    amount: '',  // Changed from 'quantity'
                    comment: ''  // Added comment field
                }
            ]
        }));
        setListId(listId + 1);
    };

    const updateIngredient = (updatedIngredient) => {
        setFormData(prevData => {
            const newData = {
                ...prevData,
                ingredients: prevData.ingredients.map(ing => 
                    ing.listId === updatedIngredient.listId ? updatedIngredient : ing
                )
            };
            console.log("Updated formData:", newData);
            return newData;
        });
    };

    const removeIngredient = (listId) => {
        const updatedIngredients = formData.ingredients.filter(ingredient => ingredient.listId !== listId);
        setFormData(prevData => ({
            ...prevData,
            ingredients: updatedIngredients
        }));
    };

    const renderIngredients = formData.ingredients.map(ingredient => <AddIngredient
        key={ingredient.listId}
        ingredient={ingredient}
        ingredients={ingredients}
        listId={listId - 1}
        updateIngredient={updateIngredient}
        removeIngredient={removeIngredient}
    />)

    const deleteRecipe = () => {
        axiosInstance.delete(`recipes/${formData.id}`, {
            params: {
                username: profile.email  // Set the username as a query parameter
            }
        })
            .then(response => {
                console.log('Recipe deleted successfully', response.data);
                navigate('/browse');
            })
            .catch(error => {
                console.error('Error deleting recipe', error);
            });
    };

    return (
        <div className="edit-recipe-container">
            <div className="m-3">
                <h1 className="h3 bg-dark text-bg-primary mt-2">Edit Recipe</h1>
                <Form.Group className="mb-1" controlId="formBasicName">
                    <Form.Label>Recipe Name:</Form.Label>
                    <Form.Control
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        placeholder="Name"
                    />
                </Form.Group>
                <Form.Group className="mb-1" controlId="formBasicDescription">
                    <Form.Label>Description:</Form.Label>
                    <Form.Control
                        name="description"
                        value={formData.description}
                        onChange={handleInputChange}
                        placeholder="Description"
                    />
                </Form.Group>
                <Form.Group className="mb-1 mb-5" controlId="formBasicImageUrl">
                    <Form.Label>Image URL:</Form.Label>
                    <Form.Control
                        name="imageUrl"
                        value={formData.imageUrl}
                        onChange={handleInputChange}
                        placeholder="URL"
                    />
                </Form.Group>
                <Form.Group className="mb-1 mb-5" controlId="formBasicMinutesToCook">
                    <Form.Label>Time to Cook:</Form.Label>
                    <Form.Control
                        name="minutesToCook"
                        value={formData.minutesToCook}
                        onChange={handleInputChange}
                        placeholder="minutes"
                        type={"number"}
                    />
                </Form.Group>
                <Form.Group className="mb-1 mb-5" controlId="formBasicServings">
                    <Form.Label>Servings:</Form.Label>
                    <Form.Control
                        name="servings"
                        value={formData.servings}
                        onChange={handleInputChange}
                        placeholder="servings"
                        type={"number"}
                    />
                </Form.Group>
                <Form.Group className="mb-1 mb-5" controlId="formBasicDiet">
                    <Form.Label>Diet:</Form.Label>
                    <Form.Select
                        name="diet"
                        value={formData.diet}
                        onChange={handleInputChange}
                    >
                        <option value="">Select Diet</option>
                        <option value="MEAT">MEAT</option>
                        <option value="VEGAN">VEGAN</option>
                        <option value="VEGETARIAN">VEGETARIAN</option>
                        <option value="FISH">FISH</option>
                        <option value="SEAFOOD">SEAFOOD</option>
                    </Form.Select>
                </Form.Group>
                <Row>
                    <Col>Ingredient</Col>
                    <Col>Amount</Col>
                    <Col>Unit</Col>
                    <Col>Quantity</Col>
                    <Col xs={1}></Col>
                </Row>
                <hr />
                <Row>
                    <br></br>
                </Row>
                {renderIngredients}
                <Row>
                    <br></br>
                    <Button
                        variant='warning'
                        onClick={addIngredient}
                        className="mt-1"
                    >Add Ingredient</Button>
                </Row>
                <Button
                    variant="primary"
                    onClick={handleSubmit(onSubmit)}
                    type="submit"
                    className="mb-5"
                    disabled={!isCreator}
                >
                    Update
                </Button>
                <Button
                    variant="secondary"
                    onClick={deleteRecipe}
                    type={"button"}
                    className="mb-5"
                    disabled={!isCreator}
                >
                    Delete
                </Button>
                <Button
                    variant="secondary"
                    onClick={handleAddToMyRecipes}
                    type="button"
                    className="mb-5"
                    disabled={isCreator}
                >
                    Add to my Recipes
                </Button>
            </div>
        </div>
    );
}

export default EditRecipe;
