import React, { createContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [profile, setProfile] = useState(null);
    const navigate = useNavigate();

    const handleLoginSuccess = (profileData) => {
        setIsLoggedIn(true);
        setProfile(profileData);
    };

    const handleLogout = () => {
        setIsLoggedIn(false);
        setProfile(null);
        // navigate('/login');
    };

    return (
        <AuthContext.Provider value={{ isLoggedIn, profile, handleLoginSuccess, handleLogout }}>
            {children}
        </AuthContext.Provider>
    );
};